/*
 * 资金管理相关接口
 * */
import request from '@/utils/request'

/*
 * 获取充值二维码
 * */
export function getWorkbenchQRCode(params) {
  return request({
    url: '/work/mini_work/get/qrcode',
    method: 'get',
    params,
  })
}

// 通用获取二维码
export function getWorkMakeMiniQrcode(params) {
  return request({
    url: '/work/make_mini_qrcode',
    method: 'post',
    params,
  })
}

/*
 * 获取绑定人列表
 * */
export function getIsBindingList(params) {
  return request({
    url: '/work/mini_work/get/user_list',
    method: 'get',
    params,
  })
}

/*
 * 解绑用户
 * */
export function unbundlingUser(data) {
  return request({
    url: '/work/mini_work/delete/user',
    method: 'post',
    data,
  })
}

// 员工权限设置
export function setBossAuth(data) {
  return request({
    url: '/work/mini_work/set_boss',
    method: 'post',
    data,
  })
}

// 员工权限设置新

export function setPermission(data) {
  return request({
    url: '/work/mini_work/setPermission',
    method: 'post',
    data,
  })
}
